<template>
  <div class="sidebar" :class="{ active: toggle }">
    <div class="sidebar_top">
      <div class="icon_up">
        <img src="@/assets/profile_assets/arrow-up-sidebar.svg" alt="" />
      </div>

      <div class="icon_close" @click="closeActive">
        <img src="@/assets/profile_assets/closeSidebar.svg" alt="" />
      </div>
    </div>

    <h3>{{ $localize('profile')['history']['payment'] }}</h3>

    <div class="sidebar-lists">
      <ul>
        <li>
          <img src="@/assets/profile_assets/sidebar-list.svg" alt="" />
          <div class="title">
            <h4>{{ $localize('profile')['contract']['number'] }}:</h4>
            <p v-if="check.contract_id">№{{check.contract_id}}</p>
            <p v-else>№ <i>Не задано</i></p>
          </div>
        </li>

        <li>
          <img src="@/assets/profile_assets/sidebar-time.svg" alt="" />
          <div class="title">
            <h4>{{ $localize('profile')['history']['time'] }}:</h4>
            <p>{{check.time}}</p>
          </div>
        </li>

        <li>
          <img src="@/assets/profile_assets/sidebar-card.svg" alt="" />
          <div class="title">
            <h4>
              {{ $localize('profile')['history']['card'] }} : {{ (check.payment_system == 'ACCOUNT' || check.payment_system == 'DEPOSIT') ? $localize('balancePopup')['personalBalance']  : $localize('profile')['cardTitleDefault']  }}
            </h4>
            <p>{{check.card_number}}</p>
          </div>
        </li>

        <li>
          <img src="@/assets/profile_assets/sidebar-money.svg" alt="" />
          <div class="title">
            <h4>{{ $localize('profile')['history']['sum'] }}</h4>
            <p>
              <a href="javascript:"> {{check.amount}} {{ $localize('profile')['currency'] }} </a>
            </p>
          </div>
        </li>
      </ul>
    </div>

    <div class="sidebar_links">
      <div class="links">
        <img src="@/assets/profile_assets/share.svg" alt="" />
        <a href="javascript:">{{ $localize('button')['share'] }}</a>
      </div>

      <div class="links" style="cursor: pointer" @click="closeActive">
        <img src="@/assets/profile_assets/closeSidebarBottom.svg" alt="" />
        <a href="javascript:">{{ $localize('button')['close'] }}</a>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    toggle: {
      type: Boolean,
    },
    check: {
      type: Object,
    },
  },
  methods: {
    closeActive() {
      this.$emit('close-active', false);
    },
  },
};
</script>

<style lang="scss" scoped>
  .sidebar {
    background: $white;
    max-width: 400px;
    width: 100%;
    top: 96px;
    min-height: calc(100vh - 96px);
    transform: translateX(500px);
    padding: 80px 32px 32px;
    transition-duration: 0.5s;
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 900;
    overflow: auto;

    &.active {
      transform: translateX(0px);
    }

    &_top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;

      .icon_up {
        padding: 10px;
        background: rgba(102, 16, 245, 0.1);
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .icon_close {
        cursor: pointer;
      }
    }

    h3 {
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      display: flex;
      align-items: center;
      color:  $black;
      margin-bottom: 16px;
    }

    &-lists {
      ul {
        li {
          padding: 13px 0;
          display: flex;
          align-items: center;
          border-bottom: 1px solid rgba(39, 39, 39, 0.1);

          &:last-child {
            border: none;
            margin-bottom: calc(80px - 13px);
          }

          h4 {
            font-weight: normal;
            font-size: 15px;
            line-height: 18px;
            color: #272727;
          }

          p {
            font-weight: 600;
            font-size: 15px;
            line-height: 18px;
            color: #272727;

            a {
              font-weight: bold;
              font-size: 16px;
              line-height: 19px;
              color: $main;
            }
          }

          img {
            margin-right: 6px;
          }
        }
      }
    }
  }

  .sidebar_links {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    .links {
      display: flex;
      flex-direction: column;
      align-items: center;

      a {
        font-size: 14px;
        line-height: 16px;
        color: $main;
        margin-top: 8px;
        padding: 0 16px;
      }
    }
  }

  @media (max-width: 575px) {
    .sidebar {
      max-width: 100%;
      margin-left: 0px;
      margin-top: 48px;
      padding: 24px;
      transform: translateY(100vh);
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      height: auto;
      min-height: auto;
      top: auto;

      &::-webkit-scrollbar {
        width: 1px;
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        background: $black;
        opacity: 0.3;
        border-radius: 4px;
        left: 50%;
        top: 10px;
        transform: translateX(-50%);
        width: 56px;
        height: 4px;
      }

      &.active {
        transform: translateY(0px);
      }
    }
    .sidebar_top {
      margin-bottom: 16px;

      .icon_up {
        img {
          width: 24px;
        }
      }
    }
    .sidebar-lists ul li:last-child {
      margin-bottom: 32px;
    }
  }
</style>
