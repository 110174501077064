<template>
  <div class="history">
    <div class="container fluid">
      <h1>{{ $localize("profile")["history"]["title"] }}</h1>

      <div class="row" v-if="contracts.length !== 0 || date.length">
        <div class="col-xl-4 col-ml-4">
          <ul>
            <li>
              <a href="#payments" class="toggle linkSide" @click="addActive(0)">
                {{ $localize("profile")["history"]["payments"] }}
              </a>
            </li>

            <li>
              <a href="#docs" class="linkSide" @click="addActive(1)">
                {{ $localize("profile")["history"]["documents"] }}
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="row" v-if="contracts.length !== 0 || date.length">
        <div class="col-12">
          <div v-if="!payments" class="row">
            <loading v-if="loading" size="lg" />

            <div class="col-12" v-else v-for="(col, i) in date" :key="i">
              <history-table
                :historyDate="col.historyTitle"
                :historyItem="col.historyItem"
                @add-check="addCheck"
                @push-data="pushData"
              />
            </div>
          </div>

          <div v-else class="row">
            <div class="col-12">
              <loading v-if="loading" size="lg" />

              <div class="row" v-else v-for="(n, i) in contracts" :key="i">
                <document-card :pdf="pdf" @contract-id="contractId" v-bind="n" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="no-contract" v-else>
        {{ $localize("profile")["history"]["empty"] }}
      </div>

      <my-overlay :active="toggle" @closeOverlay="toggle = !toggle" />

      <sidebar-check @close-active="closeActive" :toggle="toggle" :check="check" />
    </div>
  </div>
</template>

<script>
import { groupBy } from "lodash";
import { mapGetters } from "vuex";

import HistoryTable from "@/components/profile/HistoryTable.vue";
import DocumentCard from "@/components/profile/DocumentCard.vue";
import SidebarCheck from "@/components/profile/SidebarCheck.vue";

// mixins
import hostNameMixin from "@/mixins/hostName.mixin";
import MyOverlay from "@/components/UI/MyOverlay.vue";

export default {
  name: "profileHistory",
  mixins: [hostNameMixin],
  data: () => ({
    payments: 0,
    toggle: false,
    intlnowDate: null,
    group: null,
    date: [],
    check: {},
    loading: false,
    contracts: [],
    pdf: null,
  }),
  components: { HistoryTable, DocumentCard, SidebarCheck, MyOverlay },
  computed: {
    ...mapGetters("buyers", ["cardUser", "balance"]),
  },
  methods: {
    addActive(i) {
      let link = document.querySelectorAll(".linkSide");

      link.forEach((e) => {
        e.classList.remove("toggle");
      });

      if (!link[i].classList.contains("toggle")) {
        link[i].classList.add("toggle");
      }

      this.payments = i;
    },
    addCheck(n) {
      this.toggle = n.active;
    },
    closeActive(active) {
      this.toggle = active;
    },
    pushData(val) {
      let card = val.data.card_id;
      this.check = { ...val.data };
      this.cardUser.find((n) => {
        if (n.id == card) {
          this.check.card_number = n.pan;
        }
      });
    },
    contractId(id) {
      this.loading = true;

      this.$axios
        .post("buyer/contract", {
          contract_id: id.contract_id,
        })
        .then((response) => {
          this.pdf = `${this.hostName}${response.data.contracts.doc_pdf.slice(1)}`;

          this.loading = false;

          open(this.pdf);
        });
    },
  },
  async created() {
    this.loading = true;
    this.$axios.get("buyer/payments").then((res) => {
      this.group = groupBy(res.data.payments, "date");

      for (const [key, val] of Object.entries(this.group)) {
        const filteredContracts = val.filter(
          (contract) => !(contract.status == 0 && contract.type == "fill")
        );
        // * (contract.status == 0 || contract.status == 0 && contract.type == 'fill')
        const data = {
          historyTitle: filteredContracts.length ? key : "",
          historyItem: filteredContracts,
        };
        this.date.push(data);
        this.date = this.date.sort((a, b) =>
          b.historyTitle.localeCompare(a.historyTitle)
        );
      }
      this.loading = false;
    });

    this.$axios
      .get(`buyer/cards`)
      .then((res) => {
        const { data } = res;
        const info = data.data;
        this.$store.dispatch("buyers/fetchCardUser", {
          cards: info,
          deposit: this.balance.deposit,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    this.$axios.get("buyer/contracts").then((res) => {
      this.contracts = res.data.contracts;

      this.loading = false;
    });
  },
};
</script>

<style lang="scss" scoped>
h3 {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: $black;
  margin-bottom: 16px;
}

.history {
  padding: 48px 44px;
  min-height: calc(100vh - 96px);
  background: $grey;
  width: 100%;

  h1 {
    font-weight: 900;
    font-size: 32px;
    line-height: 40px;
    display: flex;
    align-items: center;
    color: $black;
    margin-bottom: 24px;
  }

  ul {
    width: 100%;
    display: flex;
    margin-bottom: 24px;

    li {
      width: 50%;

      &:first-child {
        a {
          border-bottom-left-radius: 8px;
          border-top-left-radius: 8px;
        }
      }

      &:last-child {
        a {
          border-bottom-right-radius: 8px;
          border-top-right-radius: 8px;
        }
      }

      a {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px;
        border: 1px solid #6610F5;
        color: $main;

        &.toggle {
          color: white;
          background: $main;
        }
      }
    }
  }
}

.no-contract {
  margin-top: 24px;
  font-size: 28px;
}

@media (max-width: 1199px) {
  .no-contract {
    text-align: center;
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .history {
    padding: 27px 0px;
    margin-right: -16px;
    margin-left: -16px;
    width: auto;
  }
}

@media (max-width: 575px) {
  .history {
    background: $white;

    h1 {
      font-weight: 900;
      font-size: 20px;
      line-height: 25px;
      margin-bottom: 16px;
    }
  }

  h3 {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 16px;
  }
}
</style>
