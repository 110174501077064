import config from '../config';

export default {

  data() {
    return {
      hostName: process.env.VUE_APP_STORAGE_DOMAIN,
    };
  },
  // watch: {
  //   documentUrl() {
  //     if (!/test/.test(config.API_URL)) {
  //       return this.hostName = 'https://newres.paymart.uz';
  //     }
  //   },
  // },
  created() {
    if (/test/.test(config.API_URL)) {
      return this.hostName = 'https://ari.paymart.uz/';
    }
  },
};