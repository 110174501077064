<template>
  <div class="dateTable">
    <div class="row">
      <div class="col-12">
        <h3>{{ historyDate }}</h3>
      </div>
      <div
        v-for="(paidPeriod, i) in historyItem"
        class="col-xl-3 col-ml-3 col-lg-4 col-sm-6"
        :key="i"
      >
        <history-card @add-check="addCheck" :index="i" v-bind="paidPeriod" />
      </div>
    </div>
  </div>
</template>

<script>
import HistoryCard from "./HistoryCard.vue";

export default {
  props: {
    historyDate: {
      type: String,
    },
    historyItem: {
      type: Array,
    },
  },
  components: {
    HistoryCard,
  },
  methods: {
    addCheck(active) {
      this.$emit("add-check", active);
      this.historyItem.find((n, i) => {
        if (i === active.index) {
          this.$emit("push-data", { data: n });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
h3 {
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: $black;
  margin-bottom: 16px;
}

@media (max-width: 575px) {
  h1 {
    font-size: 16px;
    line-height: 19px;
  }
}

@media (min-width: 992px) {
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.3333333333% !important;
  }
}

@media (min-width: 1200px) {
  .col-ml-3 {
    flex: 0 0 auto;
    width: 25% !important;
  }
}

@media (min-width: 1400px) {
  .col-xl-3 {
    width: calc(100% / 12 * 3) !important;
  }
}
</style>
