<template>
  <div class="col-12">
    <div class="row">
      <div class="col-12">
        <h3>{{ next_pay }}</h3>
      </div>
      <div class="col-xl-3 col-ml-3 col-lg-4 col-sm-6">
        <div class="doc_card">
          <div class="top">
            <div class="card_img">
              <img :src="peperList" alt="" />
            </div>
          </div>
          <div class="titles">
            <h4>{{ $localize('profile')['contract']['number'] }}</h4>
            <p>#{{ contract_id }} от {{ created_at }}</p>
            <a href="javascript:" @click="pushId(contract_id)">
              {{ $localize('button')['open'] }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import peperAdd from '@/assets/profile_assets/paper-add.svg';
import peperList from '@/assets/profile_assets/newspaper.svg';

export default {
  props: {
    contract_id: {
      type: Number,
    },
    next_pay: {
      type: String,
    },
    created_at: {
      type: String,
    },
  },
  data: () => ({
    peperAdd,
    peperList,
    loading: false,
  }),
  methods: {
    pushId(id) {
      this.$emit('contract-id', { contract_id: id });
    },
  },
};
</script>
<style lang="scss" scoped>
  h3 {
    font-size: 24px;
    line-height: 29px;
    color: $black;
    margin-bottom: 16px;
  }
  .doc_card {
    background: $white;
    padding: 16px;
    border-radius: 8px;
    margin-bottom: 32px;
    transition: 0.33s;

    &:hover {
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
    }

    .top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;

      .card_img {
        padding: 6px;
        background: rgba(102, 16, 245, 0.1);
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      span {
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: $black;
        opacity: 0.3;
      }
    }

    h4 {
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: $black;
      font-weight: 400;
      margin-bottom: 4px;
    }

    p {
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: $black;
      margin-bottom: 16px;
    }

    a {
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      align-items: center;
      color: $main;
    }
  }
</style>
