<template>
  <div class="histoy-card" @click="$emit('add-check', { active: true, index: index })">
    <div class="top_card">

      <div class="icon">
        <img src="@/assets/profile_assets/arrow-up.svg" alt="" />
      </div>

      <div class="time">
        {{ time }}
      </div>

    </div>
    <p>{{ paymentTypes }}</p>

    <p v-if="type != 'A2C' && type != 'user'">
      <span>{{ $localize('profile')['history']['underContract'] }} {{ contract_id }}</span>
    </p>

    <h4>{{ this.$numberFormat(amount) }} {{ $localize('profile')['currency'] }}</h4>

  </div>
</template>

<script>

export default {
  name: 'HistoryCard',
  props: {
    card_id: Number,
    payment_id: Number,
    contract_id: Number,
    amount: String,
    type: String,
    payment_system: String,
    date: String,
    time: String,
    index: Number,
  },
  computed: {
    paymentTypes() {
      return this.type ? this.$localize('profile')['history']['paymentType'][this.type] : this.$localize('profile')['history']['paymentType']['unknown'];
    },
  },
};
</script>

<style lang="scss" scoped>
.histoy-card {
  padding: 16px;
  background: $white;
  border-radius: 8px;
  margin-bottom: 32px;
  transition: 0.33s;
  min-height: 158px;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
  }

  .top_card {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    .icon {
      padding: 6px;
      background: rgba(102, 16, 245, 0.1);
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .time {
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;

      /* black */

      color: $black;

      opacity: 0.3;
    }
  }

  p {
    font-size: 14px;
    color: $black;
    span {
      margin-top: 4px;
      display: inline-block;
    }
  }

  h4 {
    margin-top: 16px;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: $main;
  }
}

@media (max-width: 575px) {
  .histoy-card {
    display: flex;
    align-items: center;
    padding: 0px;
    margin-bottom: 16px;

    .top_card {
      margin-bottom: 0px;

      .time {
        display: none;
      }
    }

    p {
      font-weight: normal;
      font-size: 13px;
      line-height: 15px;
      margin-left: 8px;
      margin-bottom: 0px;
    }

    h4 {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      margin-left: auto;
    }
  }
}
</style>
